import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Container, Grid } from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import { inspirationMainImage } from "./assets";
import ShoppingCard from "../../../components/src/ShoppingCard.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";

const shoppingData = [
  {
    title: "Wedding decorations",
    id: 1,
    description:
      "Step into a world of elegance and romance at our breathtaking wedding venues.",
    image_url:
      "https://www.thepackersmovers.com/blog/wp-content/uploads/2022/05/Latest-Home-Decoration-Ideas-for-Indian-Wedding.jpg",
  },
  {
    id: 2,
    title: "Mehendi designs",
    description:
      "Your wedding day is one of the most significant and memorable days of your life",
    image_url:
      "https://photosly.net/wp-content/uploads/2024/02/arabic-mehndi-design-easy-photo24.jpg",
  },
  {
    title: "Wedding photography",
    id: 3,
    description:
      "Step into a world of elegance and romance at our breathtaking wedding venues",
    image_url:
      "https://kamatharjun.com/wp-content/uploads/2019/07/AV-wed15.jpeg",
  },
  {
    title: "Wedding songs",
    id: 4,
    description:
      "A destination wedding offers a unique and magical experience that combines your special day ",
    image_url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX_jto6fR0WfN4Sw6HoT52UNowkMjKLVjXVom22m5EdzTAB3vTWFdDaWwUaw&s",
  },
  {
    id: 5,
    title: "Wedding decorations",
    image_url:
      "https://www.thepackersmovers.com/blog/wp-content/uploads/2022/05/Latest-Home-Decoration-Ideas-for-Indian-Wedding.jpg",
    description:
      "Step into a world of elegance and romance at our breathtaking wedding venues.",
  },
  {
    id: 6,
    title: "Mehendi designs",
    image_url:
      "https://photosly.net/wp-content/uploads/2024/02/arabic-mehndi-design-easy-photo24.jpg",
    description:
      "Your wedding day is one of the most significant and memorable days of your life",
  },
];
// Customizable Area End

import InspirationsController, { Props } from "./InspirationsController";

export default class Inspirations extends InspirationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={this.state.isLoading} />
        <CustomContainer>
          <InspirationItemStyle component="section" id="inspiration">
            <Typography className="header-inspiration" component="h3">
              Inspirations
            </Typography>
            <Typography className="description-inspiration">
              Every great wedding begins with a spark of inspiration—a vision of
              the perfect day that reflects your unique love story. At
              Happiffie, we’re here to help you turn that vision into reality.
              Our Wedding Inspiration section is designed to ignite your
              creativity, offering a wealth of ideas to inspire every detail of
              your celebration. Explore real weddings, expert tips, and the
              latest trends to help you dream big and plan with confidence. As
              you browse through our inspiration, imagine the endless
              possibilities for your special day, and let us be your partner in
              bringing your dream wedding to life.
            </Typography>
            <Box className="inspiration-main-image-container">
              <img
                src={inspirationMainImage}
                className="inspiration-main-image"
                alt="image"
              />
            </Box>
            <Typography className="header-inspiration" component="h3">
              Browse by Category
            </Typography>
            <Typography className="description-inspiration">
              Choosing the right outfit for each function is essential to
              looking and feeling your best throughout your wedding
              celebrations. From the engagement party to the reception, each
              event calls for a different style..Browse outfits by function to
              ensure each look is perfectly suited to the occasion, balancing
              elegance with practicality. Mix and match styles to create a
              cohesive wardrobe that carries you through every moment of your
              special day.
            </Typography>
            <Grid spacing={3} container className="inspiration-item-container">
              {shoppingData.map((data, index) => (
                <Grid item sm={6} md={4} lg={3} xs={12} key={index}>
                  <ShoppingCard
                    image_url={data.image_url}
                    id={data.id}
                    description={data.description}
                    data-test-id="shopping-card"
                    onCardClick={this.redirectToInspirationItemsPage}
                    title={data.title}
                  />
                </Grid>
              ))}
            </Grid>
          </InspirationItemStyle>
        </CustomContainer>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});

const InspirationItemStyle = styled(Box)(({ theme }) => ({
  padding: "56px 0 0",
  "& .header-inspiration": {
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "Poppins",
    lineHeight: "24px",
    textAlign: "center",
    color: "#44403C",
  },
  "& .description-inspiration": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#57534E",
    margin: "16px 0",
  },
  "& .inspiration-main-image-container": {
    width: "100%",
    height: "571px",
    marginBottom: "62px",
    "& .inspiration-main-image": {
      height: "100%",
      objectFit: "cover",
      width: "100%",
    },
  },
  "& .inspiration-item-container": {
    marginTop: "-12px",
    "> .MuiGrid-item": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      paddingTop: "32px",
    },
  },
}));

// Customizable Area End
