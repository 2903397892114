import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall, clearStorageData, redirectToPage } from "../../../components/src/utils";
import { toast } from "react-toastify";
import {setStorageData } from "../../../framework/src/Utilities";

export const baseURL = require("../../../framework/src/config.js");

type forgotWebResponseJson = { errors: { otp: string }[] } & {
  meta: { token: string; refresh_token: string };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: {
    email: string;
  };
  isLoading: boolean;
  inValidEmail: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      formData: {
        email: "",
      },
      isLoading: false,
      inValidEmail: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webApiRequestCallId === this.forgotApiCallId) {
        this.handleForgotApiResponse(webResponseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    await clearStorageData();
  };

  handleForgotApiResponse = (webResponseJson: forgotWebResponseJson) => {
    if (Array.isArray(webResponseJson.errors)) {
      if (webResponseJson.errors[0].otp) {
        this.setState({
          inValidEmail: true,
        });
        toast.error("Account is not found or not activated. Please try again.");
      }
    }
    if (webResponseJson?.meta?.token) {
      setStorageData("otp_token", webResponseJson.meta.token);
      toast.success("OTP send successfully.");
      setStorageData("vendorLogin", true)
      this.props.navigation.navigate("ShowOtp", {
        id: 1,
      });
    }
  };

  goToLogin = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message); 
  };

  forgotPasswordWithEmail = async (values: { email: string }) => {
    this.setState({
      isLoading: true,
    });
    const bodyDate = {
      type: "email_account",
      attributes: {
        email: values.email.trim(),
      },
    };
    setStorageData("email", values.email.trim());
    this.forgotApiCallId = await apiCall({
      endPoint: "bx_block_forgot_password/otps",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({ data: bodyDate }),
    });
  };

  changeEmailValid = () => {
    this.setState({ inValidEmail: false });
  };

  redirectFromForgotPasswordVendorToHomePage = () => {
    redirectToPage(this.props.navigation, "LandingPageWeb");
  };

  // Customizable Area End
}
