import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall, clearStorageData, redirectToPage } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";

export const baseURL = require("../../../framework/src/config.js");

interface IReference {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
type WebResponseJson = { errors: string[] } & {
  data: { id: number; attributes: { email: string } };
  meta: { token: string };
};

interface ITermsCondition {
  id: number;
  account_id: null;
  created_at: string;
  updated_at: string;
  description: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: {
    reference_id: string;
    email: string;
    password: string;
    fullName: string;
    gstNumber:string;
    businessName:string;
    businessCategory:string;
    primaryContact:string;
    secondaryNumber:string;
    showPassword: boolean;
    isChecked: boolean;
    isTermsChecked: boolean;
    isPrivacyChecked:boolean;
    day: string, month: string, year: string ;
    serviceProvided:string;
    phoneId:string;
    phoneIdNo:string;

  };
  referenceData: IReference[];
  serviceData:IReference[]
  isLoading: boolean;
  termsConditionData: ITermsCondition[];
  privacyData: ITermsCondition[];
  openTermsPrivacyModal: { open: boolean; modalName: "terms" | "privacy" };
  isInvalidEmail: boolean;
  termModal:boolean;
  privacyModal:boolean;
  phoneNumberData:Array<object>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorSignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resourceApiCallId: string = "";
  serviceProvidedApicallId:string="";
  signUpEmailApiCallId: string = "";
  termsConditionApiCallId: string = "";
  privacyApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      formData: {
        email: "",
        password: "",
        fullName: "",
        businessCategory:"",
        gstNumber:"",
        businessName:"",
        primaryContact:'',
        secondaryNumber:"",
        showPassword: false,
        reference_id: "",
        serviceProvided:"",
        isChecked: false,
        day: "", month: "", year: "" ,
        isTermsChecked: false,
      isPrivacyChecked:false,
      phoneId:'',
      phoneIdNo:''
      },
      referenceData: [],
      serviceData:[],
      isLoading: true,
      termsConditionData: [],
      privacyData: [],
      openTermsPrivacyModal: { open: false, modalName: "terms" },
      isInvalidEmail: false,
      termModal:false,
      privacyModal:false,
      phoneNumberData:[{label:"a", value:"+91"},{label:"b", value:"+01"},{label:"c", value:"+90"}],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (webApiRequestCallId) {
        case this.resourceApiCallId:
          this.handleResourcesApiResponse(webResponseJson);
          break;
          case this.serviceProvidedApicallId:
            this.handleServiceApiResponse(webResponseJson);
            break;
        case this.signUpEmailApiCallId:
          this.handleSignUpAPiResponse(webResponseJson);
          break;
        case this.termsConditionApiCallId:
          this.handleTermsConditionApiResponse(webResponseJson);
          break;
        case this.privacyApiCallId:
          this.handlePrivacyApiResponse(webResponseJson);
          break;
        default:
          break;
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    await clearStorageData();
    this.getToken();
    this.getResourceList();
    this.getServiceDataList()
    this.getPrivacyDetails();
    this.getTermsConditionDetails();
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  handleResourcesApiResponse = (webResponseJson: IReference[]) => {
    if (webResponseJson) {
      this.setState({
        referenceData: webResponseJson,
      });
    }
  };
  handleServiceApiResponse = (webResponseJson: IReference[]) => {
    if (webResponseJson) {
      this.setState({
        serviceData: webResponseJson,
      });
    }
  };

  handleSignUpAPiResponse = async (webResponseJson: WebResponseJson) => {
    if (Array.isArray(webResponseJson.errors)) {
      toast.error(webResponseJson.errors[0]);
      if (webResponseJson.errors[0] == "Email has already been taken") {
        this.setState({ isInvalidEmail: true });
      }
      return;
    }

    if (webResponseJson && webResponseJson.data && webResponseJson.meta) {
      toast.success("User account created successfully.");
      await setStorageData("authToken", webResponseJson.meta.token);
      await setStorageData("email", webResponseJson.data.attributes.email);
      this.handleNavigateToShowVerification();
    }
  };

  handleNavigateToShowVerification = () => {
    this.props.navigation.navigate("ShowVerification");
  };

  goToLogin = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  getResourceList = async () => {
    this.setState({
      isLoading: true,
    });
    this.resourceApiCallId = await apiCall({
      endPoint: "account_block/references",
      method: "GET",
      contentType: "application/json",
    });
  };
  getServiceDataList = async () => {
    this.setState({
      isLoading: true,
    });
    this.serviceProvidedApicallId = await apiCall({
      endPoint: "account_block/services",
      method: "GET",
      contentType: "application/json",
    });
  };
  getTermsConditionDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.termsConditionApiCallId = await apiCall({
      endPoint: "bx_block_terms_and_conditions/terms_and_conditions",
      method: "GET",
    });
  };

  handleTermsConditionApiResponse = (webResponseJson: ITermsCondition[]) => {
    if (webResponseJson) {
      this.setState({
        termsConditionData: webResponseJson,
      });
    }
  };

  getPrivacyDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.privacyApiCallId = await apiCall({
      endPoint: "bx_block_terms_and_conditions/privacy_policies",
      method: "GET",
    });
  };

  handlePrivacyApiResponse = (webResponseJson: ITermsCondition[]) => {
    if (webResponseJson) {
      this.setState({
        privacyData: webResponseJson,
      });
    }
  };

  openModal = (open: boolean, modalName: "terms" | "privacy") => {
    this.setState({
      openTermsPrivacyModal: {
        open,
        modalName,
      },
    });
  };
  handleEmailValid = () => {
    this.setState({
      isInvalidEmail: false,
    });
  };
  getDaysInMonth = (month: string, year: string): number => {
    if (!month || !year) return 31
    const monthNumber = parseInt(month, 10)
    const yearNumber = parseInt(year, 10)
    if (
      isNaN(monthNumber) ||
      isNaN(yearNumber) ||
      monthNumber < 1 ||
      monthNumber > 12
    ) {
      return 31
    }
    return new Date(yearNumber, monthNumber, 0).getDate()
  }
  handleErrorText = (touched: boolean | undefined, errors: string | undefined) => {
    return touched && errors
  }
  signUpWithVendorEmail = async (values: {
    email: string;
    password: string;
    fullName: string;
    reference_id: string;
    businessName:string;
    month: string;
    day: string;
    year: string;
    businessCategory:string;
    primaryContact:string;
    secondaryNumber:string;
    gstNumber:string;
    serviceProvided:string
  }) => {
    this.setState({
      isLoading: true,
    });
    const bodyDate = {
      type: "email_account",
      attributes: {
        business_name: values.businessName.trim(),
        full_name: values.fullName.trim(),
        company_incorporation_date:`${values.day}/${values.month}/${values.year}`,
        business_category: values.businessCategory.trim(),
        primary_contact_no:values.primaryContact.trim(),
        secondary_contact_no:values.secondaryNumber.trim(),
        email: values.email.trim(),
        password: values.password,
        GST_number: values.gstNumber,
        role_id: 1,
        service_id: values.serviceProvided,
        reference_id: values.reference_id,
      },
    };
    this.signUpEmailApiCallId = await apiCall({
      endPoint: "account_block/accounts",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({ data: bodyDate }),
    });
  };

  redirectFromVendorSignUpToHomePage = () => {
    redirectToPage(this.props.navigation, "LandingPageWeb");
  };
  // Customizable Area End
}
