import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Container } from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import RealWeddingItemCard from "../../../components/src/RealWeddingItemCard.web";

// Customizable Area End

import RealWeddingItemsController, {
  Props,
} from "./RealWeddingItemsController";

export default class RealWeddingItems extends RealWeddingItemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={this.state.isLoading} />
        <CustomContainer>
          <RealWeddingItemStyle component="section" id="real-wedding-items">
            <Typography className="header-real-wedding" component="h3">
              Meera and Aarav’s wedding in Heritage fort, Udaipur
            </Typography>
            <Typography className="description-real-wedding">
              As you begin this beautiful journey together at the timeless
              Heritage Fort, may your love story be as enduring and cherished as
              the walls that surround you today. This remarkable venue, steeped
              in history and elegance, mirrors the strength and beauty of the
              bond you share.we believe that every love story is unique and
              deserves to be celebrated in a way that reflects the couple's
              dreams and personalities. From the first consultation to the final
              "I do," we are dedicated to making your wedding day not just an
              event, but a cherished memory that will last a lifetime. We
              specialize in bringing your vision to life, whether it's an
              intimate ceremony or a grand celebration. Our team of expert
              planners, designers, and coordinators work tirelessly to ensure
              every detail is perfect, leaving you free to enjoy every magical
              moment of your special day.
            </Typography>
            <RealWeddingItemCard
              title="Mehendi"
              imageUrl={[
                "https://www.postoast.com/wp-content/uploads/2023/07/bridal-mehndi-designs-for-full-hands.jpg",
              ]}
              description="The Mehendi ceremony is more than just a pre-wedding ritual—it's a vibrant celebration of love, culture, and beauty. As intricate patterns of henna adorn the bride's hands, they tell a story of tradition, joy, and the beginning of a new chapter in her life.we understand the importance of this cherished event. From selecting the perfect mehendi artist to creating a lively atmosphere filled with music, dance, and laughter, we ensure that every detail"
            />
            <RealWeddingItemCard
              title="Haldii"
              imageUrl={[
                "https://www.bookeventz.com/blog-old/wp-content/uploads/2021/02/Yellow-Haldi-Dress-for-Bride.jpg",
              ]}
              description="The Haldii ceremony is a vibrant and heartwarming tradition that infuses the wedding celebrations with color, love, and blessings. As turmeric paste is lovingly applied to the bride and groom, it symbolizes purification, protection, and the beginning of a joyous journey together.we honor the significance of the Haldii ceremony by creating a setting that is as special and meaningful as the ritual itself. From the bright décor to the laughter-filled moments, we ensure that this intimate gathering radiates warmth, positivity, and love, setting the perfect tone for the celebrations to come."
            />

            <RealWeddingItemCard
              title="Barat"
              imageUrl={[
                "https://media-api.xogrp.com/images/3a600998-c58f-4649-966b-28139855823b~rs_768.h-cr_167.0.2834.2000",
              ]}
              description="The Mehendi ceremony is more than just a pre-wedding ritual—it's a vibrant celebration of love, culture, and beauty. As intricate patterns of henna adorn the bride's hands, they tell a story of tradition, joy, and the beginning of a new chapter in her life.we understand the importance of this cherished event. From selecting the perfect mehendi artist to creating a lively atmosphere filled with music, dance, and laughter, we ensure that every detail"
            />
            <RealWeddingItemCard
              title="Sangit"
              imageUrl={[
                "https://www.bookeventz.com/blog-old/wp-content/uploads/2017/05/Sangeet-ceremony.jpg",
              ]}
              description="Every great wedding begins with a spark of inspiration—a vision of the perfect day that reflects your unique love story. At Happiffie, we’re here to help you turn that vision into reality. Our Wedding Inspiration section is designed to ignite your creativity, offering a wealth of ideas to inspire every detail of your celebration.
              Explore real weddings, expert tips, and the latest trends to help you dream big and plan with confidence. As you browse through our inspiration, imagine the endless possibilities for your special day, and let us be your partner in bringing your dream wedding to life."
            />
            <RealWeddingItemCard
              title="Wedding Album"
              imageUrl={[
                "https://weddingplz-res.cloudinary.com/image/upload/fl_lossy/v1/live/photo-gallery/real_wedding/343/234a-yug-and-jigyasa-24.jpg",
              ]}
              description="Every love story is unique, and so is every wedding we help bring to life. we take pride in crafting unforgettable moments that are as individual as the couples we serve. Our Wedding Album Collection is a testament to these cherished memories, capturing the magic, emotion, and beauty of each celebration we've had the honor to plan.In this gallery, you’ll find a selection of our most beloved weddings—each one a reflection of personal style, heartfelt details, and joyous occasions. These albums not only showcase our work but also serve as inspiration for your own special day."
            />
            <RealWeddingItemCard
              imageUrl={[
                "https://www.koimoi.com/wp-content/new-galleries/2023/02/kiara-advani-talks-about-her-wedding-entry-with-siddharth-malhotra-01.jpg",
                "https://c0.wallpaperflare.com/preview/796/336/873/indian-wedding-traditional-marriage-bride.jpg",
                "https://www.weddingplz.com/blog/wp-content/uploads/weddingplz-12.jpeg",
                "https://w0.peakpx.com/wallpaper/66/637/HD-wallpaper-nayanthara-sk-wedding-ceremony-south-indian-actress-lady-superstar-vignesh-shivan.jpg",
                "https://stat4.bollywoodhungama.in/wp-content/uploads/2024/07/Anant-Ambani-and-Radhika-Merchant-wedding-Kiara-Advani-and-Sidharth-620.jpg",
              ]}
            />
            <Box className="real-wedding-items-container">
              <Typography className="real-wedding-item-detail">
                Venues:
                <Typography component="span">
                  {" "}
                  JW Marriot Bengaluru, Prestige Golfshire Resort & Spa
                  (Bridgerton Tea Party, Sangeet); Amitarasa (Wedding)
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Wedding Planner:
                <Typography component="span" className="purple-text-color">
                  {" "}
                  Events & Wedding Experiences
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Decor:
                <Typography component="span">
                  {" "}
                  Blue Water Productions
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Photography:
                <Typography component="span" className="purple-text-color">
                  {" "}
                  Magic Motion Media
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Groom’s Outfits:
                <Typography component="span">
                  {" "}
                  Divyam mehta (Baraat), Raghvendra Rathore (Pheras)
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Bride’s Outfits:
                <Typography component="span">
                  {" "}
                  Forever New ( Bridgerton Tea Party), Portia & Scralett
                  (Sangeet), Samyak (Varmala)
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Makeup:
                <Typography component="span"> Kruthika Sethia</Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Entertainment:
                <Typography component="span">
                  {" "}
                  Parleen Gill (Sangeet), Mihir Chandan (Pheras)
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Encee:
                <Typography component="span"> Mitin Upadhyay</Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Choreography:
                <Typography component="span"> Thumkas and More</Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Wedding Invitation:
                <Typography component="span"> Paper Planes</Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Bartenders:
                <Typography component="span"> Flairology</Typography>
              </Typography>
            </Box>
          </RealWeddingItemStyle>
        </CustomContainer>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});

const RealWeddingItemStyle = styled(Box)({
  padding: "32px 0 24px",
  "& .header-real-wedding": {
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "500",
    lineHeight: "50px",
    textAlign: "center",
    color: "#57534E",
  },
  "& .description-real-wedding": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#57534E",
    margin: "24px 0 32px",
  },
  "& .real-wedding-items-container": {
    margin: "64px 0 0",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  "& .real-wedding-item-detail": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#292524",
    "& span": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      color: "#292524",
    },
  },
  "& .purple-text-color": {
    color: "#801188 !important",
  },
});

// Customizable Area End
