import React from "react";

// Customizable Area Start
import { Box, Button, Typography, styled } from "@mui/material";

import { WeddingLogo } from "./assets";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginSideImageWrapper from "../../../components/src/LoginSideImageWrapper.web";
import TextInputFiled from "../../../components/src/TextInputField.web";

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Email should be in a valid email format (user@example.com)")
    .required("Email is required"),
});
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordNewController";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginSideImageWrapper isLoading={this.state.isLoading}>
        <ScrollableContentBox data-test-id="ScrollableContentBox">
          <Box className="sign-up-form-container">
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.forgotPasswordWithEmail(values);
              }}
            >
              {({
                touched,
                values,
                errors,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <img
                      src={WeddingLogo}
                      className="wedding-logo"
                      data-test-id="wedding-logo"
                      alt="Wedding Logo"
                      onClick={this.redirectFromForgotToHomePage}
                    />
                    <Typography className="sign-up-form-container-text-1">
                      Forgot your password
                    </Typography>
                    <Typography className="sign-up-form-container-text-2">
                      We will send you an email that will allow you to reset
                      your password
                    </Typography>
                    <TextInputFiled
                      sx={{ marginBottom: "0px" }}
                      name="email"
                      placeholder="Enter your email"
                      label="Email"
                      data-test-id="email-input"
                      value={values.email}
                      handleChange={(e) => {
                        handleChange(e);
                        this.changeEmailValid();
                      }}
                      handleBlur={handleBlur}
                      type="email"
                      error={
                        this.state.inValidEmail
                          ? true
                          : touched.email && !!errors.email
                      }
                      helperText={
                        this.state.inValidEmail
                          ? "Account not found or not activated"
                          : touched.email && errors.email
                      }
                    />
                    <ForgotButton
                      type="submit"
                      fullWidth
                      disabled={this.state.inValidEmail}
                      data-test-id="sign-up-button"
                    >
                      Send
                    </ForgotButton>
                    <Typography className="login-main-text">
                      Return to{" "}
                      <Typography
                        data-test-id="login"
                        className="login-text"
                        onClick={() => this.goToLogin()}
                        component="span"
                      >
                        Log in
                      </Typography>
                    </Typography>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </ScrollableContentBox>
      </LoginSideImageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ScrollableContentBox = styled(Box)(({ theme }) => ({
  padding: "30px 114.96px 30px 72.32px",
  overflowY: "auto",
  height: "100%",
  width: "50%",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    height: "100%",
    width: "100%",
    overflowY: "unset",
    minHeight: "fit-content",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "30px 30px 68px",
  },
  "& .sign-up-form-container": {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
    "& form": {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "600px",
      },
    },
  },
  "& .wedding-logo": {
    aspectRation: "1",
    width: "120.62px",
    marginBottom: "24px",
    height: "60px",
    cursor: "pointer",
  },
  "& .sign-up-form-container-text-1": {
    color: "#000000",
    lineHeight: "24px",
    marginBottom: "6px",
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "600",
  },
  "& .sign-up-form-container-text-2": {
    fontFamily: "Poppins",
    color: "#78716C",
    marginBottom: "24px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
  },
  "& .login-main-text": {
    color: "#44403C",
    textAlign: "center",
    fontFamily: "Poppins",
    lineHeight: "24px",
    marginTop: "22.68px",
    fontSize: "16px",
    fontWeight: "500",
  },
  "& .login-text": {
    cursor: "pointer",
    color: "#7F1287",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
}));

const ForgotButton = styled(Button)({
  marginTop: "11.78px",
  fontFamily: "Poppins",
  padding: "19px 20px",
  fontSize: "16px",
  fontWeight: "400",
  borderRadius: "8px",
  lineHeight: "24px",
  backgroundColor: "#801187",
  border: "1px solid  #801187",
  textTransform: "unset",
  color: "#FFFFFF !important",
  "&:hover": {
    backgroundColor: "#801187",
  },
});
// Customizable Area End
