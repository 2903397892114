import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall, clearStorageData, redirectToPage } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";

export const baseURL = require("../../../framework/src/config.js");

interface IReference {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

type WebResponseJson = { errors: string[] } & {
  data: { id: number; attributes: { email: string } };
  meta: { token: string };
};

interface ITermsCondition {
  id: number;
  account_id: null;
  created_at: string;
  updated_at: string;
  description: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: {
    reference_id: string;
    email: string;
    password: string;
    fullName: string;
    showPassword: boolean;
    isTermsChecked: boolean;
    isPrivacyChecked: boolean;
  };
  referenceData: IReference[];
  isLoading: boolean;
  termsConditionData: ITermsCondition[];
  privacyData: ITermsCondition[];
  openTermsPrivacyModal: { open: boolean; modalName: "terms" | "privacy" };
  isInvalidEmail: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resourceApiCallId: string = "";
  signUpEmailApiCallId: string = "";
  termsConditionApiCallId: string = "";
  privacyApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      formData: {
        email: "",
        password: "",
        fullName: "",
        showPassword: false,
        reference_id: "",
        isPrivacyChecked: false,
        isTermsChecked: false,
      },
      referenceData: [],
      isLoading: true,
      termsConditionData: [],
      privacyData: [],
      openTermsPrivacyModal: { open: false, modalName: "terms" },
      isInvalidEmail: false,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (webApiRequestCallId) {
        case this.resourceApiCallId:
          this.handleResourcesApiResponse(webResponseJson);
          break;
        case this.signUpEmailApiCallId:
          this.handleSignUpAPiResponse(webResponseJson);
          break;
        case this.termsConditionApiCallId:
          this.handleTermsConditionApiResponse(webResponseJson);
          break;
        case this.privacyApiCallId:
          this.handlePrivacyApiResponse(webResponseJson);
          break;
        default:
          break;
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    await clearStorageData();
    this.getToken();
    this.getResourceList();
    this.getPrivacyDetails();
    this.getTermsConditionDetails();
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  handleResourcesApiResponse = (webResponseJson: IReference[]) => {
    if (webResponseJson) {
      this.setState({
        referenceData: webResponseJson,
      });
    }
  };

  handleSignUpAPiResponse = async (webResponseJson: WebResponseJson) => {
    if (Array.isArray(webResponseJson.errors)) {
      toast.error(webResponseJson.errors[0]);
      if (webResponseJson.errors[0] == "Email has already been taken") {
        this.setState({ isInvalidEmail: true });
      }
      return;
    }

    if (webResponseJson && webResponseJson.data && webResponseJson.meta) {
      toast.success("User account created successfully.");
      await setStorageData("authToken", webResponseJson.meta.token);
      await setStorageData("email", webResponseJson.data.attributes.email);
      this.handleNavigateToShowVerification();
    }
  };

  handleNavigateToShowVerification = () => {
    this.props.navigation.navigate("ShowVerification");
  };

  goToLogin = () => {
    this.props.navigation.navigate("LoginEmail");
  };

  getResourceList = async () => {
    this.setState({
      isLoading: true,
    });
    this.resourceApiCallId = await apiCall({
      endPoint: "account_block/references",
      method: "GET",
      contentType: "application/json",
    });
  };

  signUpWithEmail = async (values: {
    email: string;
    password: string;
    fullName: string;
    reference_id: string;
  }) => {
    this.setState({
      isLoading: true,
    });
    const bodyDate = {
      type: "email_account",
      attributes: {
        full_name: values.fullName.trim(),
        email: values.email.trim(),
        password: values.password,
        reference_id: values.reference_id,
      },
    };
    this.signUpEmailApiCallId = await apiCall({
      endPoint: "account_block/accounts",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({ data: bodyDate }),
    });
  };

  getTermsConditionDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.termsConditionApiCallId = await apiCall({
      endPoint: "bx_block_terms_and_conditions/terms_and_conditions",
      method: "GET",
    });
  };

  handleTermsConditionApiResponse = (webResponseJson: ITermsCondition[]) => {
    if (webResponseJson) {
      this.setState({
        termsConditionData: webResponseJson,
      });
    }
  };

  getPrivacyDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.privacyApiCallId = await apiCall({
      endPoint: "bx_block_terms_and_conditions/privacy_policies",
      method: "GET",
    });
  };

  handlePrivacyApiResponse = (webResponseJson: ITermsCondition[]) => {
    if (webResponseJson) {
      this.setState({
        privacyData: webResponseJson,
      });
    }
  };

  openModal = (open: boolean, modalName: "terms" | "privacy") => {
    this.setState({
      openTermsPrivacyModal: {
        open,
        modalName,
      },
    });
  };

  handleChangeEmailValid = () => {
    this.setState({
      isInvalidEmail: false,
    });
  };

  redirectFromSignUpToHomePage = () => {
    redirectToPage(this.props.navigation, "LandingPageWeb");
  };
  // Customizable Area End
}
