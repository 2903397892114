import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { clearStorageData, redirectToPage,  } from "../../../components/src/utils";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: {
    [x: string]: string;
    phoneNumber: string;
  };
  isLoading: boolean;
  invalidPhoneNumber: boolean;
  phoneNumberData:Array<object>;
  phoneId:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorPhoneLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginEmailApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      formData: {
        phoneNumber: "",
      },
      isLoading: false,
      invalidPhoneNumber: false,
      phoneNumberData:[{label:"a", value:"+91"},{label:"b", value:"+01"},{label:"c", value:"+90"}],
      phoneId:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    await clearStorageData();
  };
  changePhoneValid = () => {
    this.setState({ invalidPhoneNumber: false });
  };
  goToEnailLogin = () => {
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }
  

  redirectFromVendorLoginToHomePage = () => {
    redirectToPage(this.props.navigation, "LandingPageWeb");
  };

  goToOtpPage = (values:{phoneNumber:string}) => {
    setStorageData("phoneLogin", values.phoneNumber)
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorOTPLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }
  // Customizable Area End
}
