import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  hasMore: boolean;
  realWeddingData: {
    couple_name: string;
    id: number;
    location: string;
    image_url: string;
  }[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class RealWeddingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      hasMore: true,
      realWeddingData: [
        {
          couple_name: "Anjali & Vikram",
          id: 1,
          location: "Beachfront Resort, Goa",
          image_url:
            "https://www.thepackersmovers.com/blog/wp-content/uploads/2022/05/Latest-Home-Decoration-Ideas-for-Indian-Wedding.jpg",
        },
        {
          id: 2,
          couple_name: "Priya & Raj",
          location: "Palace Grounds, Jaipur",
          image_url:
            "https://photosly.net/wp-content/uploads/2024/02/arabic-mehndi-design-easy-photo24.jpg",
        },
        {
          couple_name: "Meera & Aarav",
          id: 3,
          location: "Heritage Fort, Udaipur",
          image_url:
            "https://kamatharjun.com/wp-content/uploads/2019/07/AV-wed15.jpeg",
        },
        {
          couple_name: "Devika & Sanjeet",
          id: 4,
          location: "Beachfront Resort, Goa",
          image_url:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX_jto6fR0WfN4Sw6HoT52UNowkMjKLVjXVom22m5EdzTAB3vTWFdDaWwUaw&s",
        },
        {
          id: 5,
          couple_name: "Gayatri & Shiva",
          image_url:
            "https://www.thepackersmovers.com/blog/wp-content/uploads/2022/05/Latest-Home-Decoration-Ideas-for-Indian-Wedding.jpg",
          location:
            "Baga Beach, Goa",
        },
        {
          id: 6,
          couple_name: "Shruti & Nikhil",
          image_url:
            "https://photosly.net/wp-content/uploads/2024/02/arabic-mehndi-design-easy-photo24.jpg",
          location:
            "Noor-uh- Saba, Bhopal",
        },
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }

  redirectToRealWeddingItemsPage = (id: number) => {
    this.props.navigation.navigate("RealWeddingItems", { id });
  };
  // Customizable Area End
}
