import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";

export const baseURL = require("../../../framework/src/config.js");

interface IFaqIcon {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    admin_users_id: number;
    select_img: {
      id: number;
      date: string;
      url: string;
      type: string;
    };
    unselect_img: {
      id: number;
      date: string;
      url: string;
      type: string;
    };
  };
}

interface IFaqQuestionAnswer {
  id: number;
  question: string;
  answer: string;
  admin_users_id: number;
  created_at: string;
  updated_at: string;
  faq_icons_id: number;
}

type FaqQuestionAnswerResponse = {
  data: {
    id: number;
    type: string;
    attributes: {
      id: number;
      title: string;
      questions_answers: IFaqQuestionAnswer[];
    };
  };
} & { errors: { details: string }[] };
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  activeItem: { id: number; title: string };
  faqIcons: IFaqIcon[];
  faqQuestionAnswer: IFaqQuestionAnswer[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FaqsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  faqIconsApiCallId: string = "";
  faqQuestionAnswerApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      activeItem: { id: 0, title: "" },
      faqIcons: [],
      faqQuestionAnswer: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.faqIconsApiCallId:
          this.handleFaqIconsResponse(webResponseJson);
          break;
        case this.faqQuestionAnswerApiCallId:
          this.handleFaqQuestionAnswerResponse(webResponseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.getFaqIcons();
  }

  setActiveItem = (activeItem: number, title: string) => {
    this.setState({ activeItem: { id: activeItem, title } }, () => {
      this.getFaqQuestionAnswer();
    });
  };

  getFaqIcons = async () => {
    this.setState({ isLoading: true });
    this.faqIconsApiCallId = await apiCall({
      endPoint: "bx_block_interactive_faqs/faq_icons",
      method: "GET",
    });
  };

  getFaqQuestionAnswer = async () => {
    this.setState({ isLoading: true });
    this.faqQuestionAnswerApiCallId = await apiCall({
      endPoint: `bx_block_interactive_faqs/faq_icon_ques_answers/${this.state.activeItem.id}`,
      method: "GET",
    });
  };

  handleFaqQuestionAnswerResponse = (
    webResponseJson: FaqQuestionAnswerResponse
  ) => {
    if (webResponseJson?.errors && webResponseJson?.errors[0]?.details) {
      toast.error("The FAQ question and answer could not be found");
    }
    if (webResponseJson?.data?.attributes?.questions_answers) {
      this.setState({
        faqQuestionAnswer: webResponseJson.data.attributes.questions_answers,
      });
      if (webResponseJson.data.attributes.questions_answers.length === 0) {
        toast.error(
          "No FAQ questions have been added for this section by the admin"
        );
      }
    }

    this.setState({
      isLoading: false,
    });
  };

  handleFaqIconsResponse = (webResponseJson: { data: IFaqIcon[] }) => {
    this.setState({
      faqIcons: webResponseJson.data,
      isLoading: false,
    });
    if (webResponseJson.data.length > 0) {
      this.setState(
        {
          activeItem: {
            id: webResponseJson.data[0].attributes.id,
            title: webResponseJson.data[0].attributes.title,
          },
        },
        () => {
          this.getFaqQuestionAnswer();
        }
      );
    } else {
      toast.error("No FAQ sections have been added by the admin");
    }
  };

  // Customizable Area End
}
