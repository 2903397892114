import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall, clearStorageData, redirectToPage } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";

export const baseURL = require("../../../framework/src/config.js");

type loginWebResponseJson = { errors: { failed_login: string }[] } & {
  meta: { token: string; refresh_token: string };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: {
    email: string;
    password: string;
    showPassword: boolean;
  };
  isLoading: boolean;
  inValidEmail: boolean;
  inValidPassword: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginEmailApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      formData: {
        email: "",
        password: "",
        showPassword: false,
      },
      isLoading: false,
      inValidEmail: false,
      inValidPassword: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (webApiRequestCallId === this.loginEmailApiCallId) {
        this.handleLoginApiResponse(webResponseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    await clearStorageData();
  };
  changePasswordValid = () => {
    this.setState({ inValidPassword: false });
  };
  changeEmailValid = () => {
    this.setState({ inValidEmail: false });
  };
  goToPhoneLogin = () => {
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorPhoneLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message); 
  }
  goToForgotPasswordPage = () => {
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorForgotPassword");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }
  loginWithVendorEmail = async (values: { email: string; password: string }) => {
    this.setState({
      isLoading: true,
    });
    const bodyDate = {
      type: "email_account",
      attributes: {
        email: values.email.trim(),
        password: values.password,
      },
    };
    this.loginEmailApiCallId = await apiCall({
      endPoint: "bx_block_login/logins",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({ data: bodyDate }),
    });
  };
  handleLoginApiResponse = (webResponseJson: loginWebResponseJson) => {
    if (Array.isArray(webResponseJson.errors)) {
      if (
        webResponseJson.errors[0].failed_login ===
        "Account not found, or not activated"
      ) {
        this.setState({
          inValidEmail: true,
        });
        toast.error("Account is not found or not activated. Please try again.");
        return;
      } else {
        this.setState({
          inValidPassword: true,
        });
        toast.error("The password you entered is invalid. Please try again.");
        return;
      }
    }
    if (webResponseJson.meta.token) {
      setStorageData("authToken", webResponseJson.meta.token);
      toast.success("User login successfully.");
      this.props.navigation.navigate("LandingPageWeb");
    }
  };

  redirectFromVendorLoginToHomePage = () => {
    redirectToPage(this.props.navigation, "LandingPageWeb");
  };
  // Customizable Area End
}
