import React from "react";

import {
  Box,
  // Customizable Area Start
  Button,
  Typography,
  styled,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { WeddingLogo } from "./assets";
import OtpInput from "react-otp-input";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Customizable Area End

import OtpController, { Props } from "./OtpController";
import LoginSideImageWrapper from "../../../components/src/LoginSideImageWrapper.web";

export default class ShowOtp extends OtpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginSideImageWrapper isLoading={this.state.isLoading}>
        <ScrollableContentBox>
          <Box className="sign-up-form-container">
            <Formik
              data-test-id="Formik"
              initialValues={{ otp: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.verifyEmailWithOtp(values.otp);
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldTouched,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <img
                      src={WeddingLogo}
                      className="wedding-logo"
                      alt="Wedding Logo"
                    />
                    <Typography className="sign-up-form-container-text-1">
                      Verify your email
                    </Typography>
                    <Typography className="sign-up-form-container-text-2">
                      Enter 4 digit code sent to {this.state.email}
                    </Typography>
                    <Box className="otp-container">
                      <Box className="otp-input-container">
                        <OtpInput
                          value={values.otp}
                          onChange={(otp: string) => {
                            setFieldTouched("otp", true);
                            setFieldValue("otp", otp);
                            this.changeInvalidOtpValue();
                          }}
                          data-test-id="otp-input"
                          numInputs={4}
                          hasErrored={true}
                          separator={
                            <Typography
                              component="span"
                              className="two-input-gap"
                            />
                          }
                          isInputNum={true}
                          shouldAutoFocus={true}
                          inputStyle={`otp-input ${
                            (errors.otp && touched.otp) ||
                            this.state.isInvalidOtp
                              ? "otp-input-error"
                              : ""
                          }`}
                          focusStyle="otp-input-focus"
                        />
                        {touched.otp && errors.otp && (
                          <Typography className="error-text">
                            {errors.otp}
                          </Typography>
                        )}
                        {this.state.isInvalidOtp && (
                          <Typography className="error-text">
                            You’ve entered an incorrect code, please try again
                          </Typography>
                        )}
                      </Box>
                      {this.state.canResend ? (
                        <Typography
                          className="resend-otp"
                          data-test-id="resend-otp"
                          onClick={this.handleResendClick}
                        >
                          Resend Otp
                        </Typography>
                      ) : (
                        <Typography className="otp-expire-text">
                          This code expires in{" "}
                          <Typography className="bold-text" component="span">
                            {this.formatTime(this.state.timeLeft)} minutes
                          </Typography>
                        </Typography>
                      )}
                      <SignUpButton type="submit" fullWidth>
                        Verify code
                      </SignUpButton>
                      <Typography className="login-main-text">
                        Return to{" "}
                        <Typography
                          component="span"
                          className="login-text"
                          data-test-id="login"
                          onClick={() => this.goToLogin()}
                        >
                          Log in
                        </Typography>
                      </Typography>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </ScrollableContentBox>
      </LoginSideImageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const validationSchema = Yup.object({
  otp: Yup.string()
    .length(4, "OTP must be exactly 4 digits")
    .matches(/^\d+$/, "OTP must contain only digits")
    .required("Otp is required"),
});

const ScrollableContentBox = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  width: "50%",
  padding: "62px 90.32px 62px 72px",
  height: "100%",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    height: "100%",
    padding: "30px",
    width: "100%",
    minHeight: "fit-content",
    overflowY: "unset",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "62px 90.32px 62px 72px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "30px",
  },
  "& .sign-up-form-container": {
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    "& form": {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "600px",
      },
    },
  },
  "& .otp-container": {
    flexDirection: "column",
    padding: "0 0 0 25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  "& .two-input-gap": {
    width: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "15px",
    },
  },
  "& .otp-input": {
    border: "1px solid #D6D3D1",
    borderRadius: "8px",
    width: "75px !important",
    height: "64px",
    fontSize: "24px",
    color: "#94A3B8",
    fontWeight: "400",
    caretColor: "#801187",
    [theme.breakpoints.down("sm")]: {
      width: "56px !important",
      height: "56px",
    },
  },
  "& .otp-input-error": {
    borderColor: "#F87171 !important",
    color: "#0F172A",
  },
  "& .otp-input-focus": {
    border: "1px solid #801187",
    outline: "none",
  },
  "& .otp-expire-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#75788D",
    margin: "43px 0 30.78px",
    "& .bold-text": {
      color: "#000000",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20px",
    },
  },
  "& .wedding-logo": {
    marginBottom: "24px",
    width: "120.62px",
    height: "60px",
    aspectRation: "1",
  },
  "& .sign-up-form-container-text-1": {
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "22px",
    lineHeight: "24px",
    marginBottom: "6px",
    color: "#000000",
  },
  "& .sign-up-form-container-text-2": {
    fontFamily: "Poppins",
    fontSize: "16px",
    marginBottom: "24px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#78716C",
  },
  "& .login-main-text": {
    color: "#44403C",
    fontFamily: "Poppins",
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: "500",
    marginTop: "22.68px",
  },
  "& .login-text": {
    fontFamily: "Poppins",
    cursor: "pointer",
    fontSize: "16px",
    color: "#7F1287",
    fontWeight: "500",
    lineHeight: "24px",
  },
  "& .resend-otp": {
    fontFamily: "Poppins",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "500",
    color: "#7F1287",
    margin: "47px 0 30.78px",
    lineHeight: "20px",
  },
  "& .error-text": {
    fontFamily: "Poppins",
    marginTop: "8px",
    fontSize: "12px",
    fontWeight: "400",
    color: "#DC2626",
    lineHeight: "18px",
  },
}));

const SignUpButton = styled(Button)({
  border: `1px solid #801187`,
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: "#FFFFFF",
  borderRadius: "8px",
  padding: "19px 20px",
  textTransform: "unset",
  backgroundColor: "#801187",
  "&:hover": {
    backgroundColor: "#801187",
  },
});
// Customizable Area End
