import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
  Button,
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import Header from "../../../components/src/Header.web";
import Chat from "../../../blocks/chat/src/Chat.web";
import {
  backgroundDesign,
  categoryIcon,
  checkImage,
  contactUsBackground,
  minusImage,
  doNotWorkImage,
  dropdownIcon,
  howItWorkImage,
  leftArrow,
  locationIcon,
  plusImage,
  ratingStar,
  realWedding1,
  realWedding2,
  realWedding3,
  realWedding4,
  rightArrow,
  rightArrowButton,
  shopImag1,
  shopImag2,
  shopImag3,
  shopImag4,
  uncheckImage,
  userImage,
  vendorImage,
  vendorImage2,
  vendorImage3,
  vendorImage4,
  whoAreImage,
  whyChooseImage,
  workImage,
} from "./assets";
import SearchIcon from "@mui/icons-material/Search";
import Select, { components } from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInputFiled from "../../../components/src/TextInputField.web";
import SelectField from "../../../components/src/SelectField.web";
import TextAreaFiled from "../../../components/src/TextAreaField.web";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from "swiper";
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/AppLoader.web";
import RealWeddingCard from "../../../components/src/RealWeddingCard.web";
import VendorCard from "../../../components/src/VendorCard.web";
import ShoppingCard from "../../../components/src/ShoppingCard.web";

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const breakpoints = {
  480: {
    slidesPerView: 1,
  },
  600: {
    slidesPerView: 2,
  },
  980: {
    slidesPerView: 3,
  },
};

const ButtonGroup = ({ next, previous }: any) => {
  return (
    <Box className="carousel-button-group">
      <CustomContainer maxWidth="lg">
        <Box className="carousel-button-group-container">
          <Button onClick={previous} className="previous-button">
            <img src={leftArrow} alt="left" />
          </Button>
          <Button onClick={next} className="next-button">
            <img src={rightArrow} alt="right" />
          </Button>
        </Box>
      </CustomContainer>
    </Box>
  );
};

const ControlWhere = (props: any) => {
  return (
    <components.Control {...props}>
      <img src={locationIcon} alt="location" className="location-image" />
      {props.children}
    </components.Control>
  );
};

const ControlCategory = (props: any) => {
  return (
    <components.Control {...props}>
      <img src={categoryIcon} alt="category" className="location-image" />
      {props.children}
    </components.Control>
  );
};
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isError = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };

  helperText = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  };

  carouselSection = () => {
    return (
      <CarouselStyle component="section" className="carousel" id="carousel">
        <Carousel
          data-test-id="carousel"
          className="carousel-container"
          swipeable={false}
          draggable={false}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          arrows={false}
          keyBoardControl={true}
          partialVisible={true}
          customTransition="all 0.5"
          transitionDuration={500}
          customButtonGroup={<ButtonGroup />}
        >
          {this.state.carouselItem.map((data) => {
            return (
              <div className="slider-image" key={data.id}>
                {data.attributes.img_vid_url.type === "image" && (
                  <img src={data.attributes.img_vid_url.url} alt="movie" />
                )}
                {data.attributes.img_vid_url.type === "video" && (
                  <video
                    autoPlay
                    muted
                    loop
                    className="coach-landing-page-video"
                  >
                    <source
                      src={data.attributes.img_vid_url.url}
                      type="video/mp4"
                    />
                    <source
                      src={data.attributes.img_vid_url.url}
                      type="video/ogg"
                    />
                  </video>
                )}
              </div>
            );
          })}
        </Carousel>
        <Box className="carousel-text-container" />
        <Box className="vendor-button-container">
          <CustomContainer maxWidth="lg">
            <Button
              className="vendor-button"
              data-test-id="vendor-button"
              onClick={() => this.redirectToVendorPage()}
            >
              <img src={workImage} alt="work" /> {configJSON.vendorButtonText}
            </Button>
          </CustomContainer>
        </Box>
        <Box className="filter-item-text-main-container">
          <CustomContainer maxWidth="lg">
            <Box className="filter-item-text-container">
              <Typography className="main-heading" component="h2">
                Your Dream Wedding Made Perfect
              </Typography>
              <Typography className="second-heading" component="h3">
                Guiding you towards the crafting timelessMoments: Curated
                wedding Planning tailored to your love story
              </Typography>
              <Box className="search-container">
                <Box className="select-dropdown-container">
                  <SelectDropdown
                    data-test-id="location-select"
                    classNamePrefix="select-dropdown"
                    placeholder="Where"
                    components={{
                      Control: ControlWhere,
                      DropdownIndicator: () => (
                        <img
                          src={dropdownIcon}
                          alt="dropdown"
                          className="dropdown"
                        />
                      ),
                    }}
                    NoOptionsMessage="No city found"
                    options={[{ label: "Gujarat", value: "Gujarat" }]}
                  />
                  <SelectDropdown
                    placeholder="Category"
                    data-test-id="category-select"
                    NoOptionsMessage="No category found"
                    components={{
                      Control: ControlCategory,
                      DropdownIndicator: () => (
                        <img
                          src={dropdownIcon}
                          alt="dropdown"
                          className="dropdown"
                        />
                      ),
                    }}
                    classNamePrefix="select-dropdown"
                    options={[{ label: "Destination", value: "Destination" }]}
                  />
                </Box>
                <Button className="search-button">
                  <SearchIcon /> Search
                </Button>
              </Box>
              <Typography component="h4" className="third-heading">
                Search over 230,000 local professionals with review, pricing and
                availability
              </Typography>
              <Box className="check-box-controller">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isChecked"
                      icon={
                        <img
                          src={uncheckImage}
                          alt="unchecked"
                          className="checked-unchecked-image"
                        />
                      }
                      checkedIcon={
                        <img
                          src={checkImage}
                          alt="unchecked"
                          className="checked-unchecked-image"
                        />
                      }
                    />
                  }
                  label="Full wedding planning/services"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isChecked"
                      icon={
                        <img
                          src={uncheckImage}
                          alt="unchecked"
                          className="checked-unchecked-image"
                        />
                      }
                      checkedIcon={
                        <img
                          src={checkImage}
                          alt="unchecked"
                          className="checked-unchecked-image"
                        />
                      }
                    />
                  }
                  label="Customized vendor search"
                />
              </Box>
            </Box>
          </CustomContainer>
        </Box>
      </CarouselStyle>
    );
  };

  statSection = () => {
    return (
      <StatsContainer component="section" id="stats-container">
        <Box className="starts-main-container">
          <Box className="stats-container">
            <Box className="stat-item">
              <Typography component="h2">15+</Typography>
              <Typography>Experience in wedding industry</Typography>
            </Box>
            <Box className="divider-1">
              <Box className="divider" />
            </Box>
            <Box className="stat-item">
              <Typography component="h2">1500+</Typography>
              <Typography>No. of wedding done</Typography>
            </Box>
            <Box className="divider-2">
              <Box className="divider" />
            </Box>
            <Box className="stat-item">
              <Typography component="h2">1500+</Typography>
              <Typography>No. of venues done</Typography>
            </Box>
            <Box className="divider-3">
              <Box className="divider" />
            </Box>
            <Box className="stat-item">
              <Typography component="h2">180+</Typography>
              <Typography>No. of trusted vendors worked</Typography>
            </Box>
            <Box className="divider-4">
              <Box className="divider" />
            </Box>
            <Box className="stat-item stat-item-fix-length">
              <Typography component="h2">100+</Typography>
              <Typography>Wedding Vendors</Typography>
            </Box>
          </Box>
        </Box>
      </StatsContainer>
    );
  };

  whoAreSection = () => {
    return (
      <WhoAreSection component="section" id="who-are">
        <MainTitle>Who we are</MainTitle>
        <Box className="image-with-overlay" />
      </WhoAreSection>
    );
  };

  passionateSection = () => {
    return (
      <PassionateContainer component="section" id="passionate">
        <Typography className="passionate-main-text">
          We are a team of passionate and experienced wedding planners
        </Typography>
        <Typography className="passionate-second-main-text">
          At Happilife Wedding Planner, our mission is to provide exceptional
          wedding planning services that exceed your expectations. We believe in
          crafting personalized weddings that reflect your personality, style,
          and love. Our goal is to ensure that every detail is flawlessly
          executed, allowing you to enjoy a stress-free and joyous
          celebration.From concept to execution.
        </Typography>
      </PassionateContainer>
    );
  };

  whyChooseSection = () => {
    return (
      <WhyChooseUsContainer component="section" id="why-choose-us">
        <MainTitle>Why choose us</MainTitle>
        <Box className="text-image-container">
          <ol>
            <li>
              At Happilife Wedding Planner, our mission is to provide
              exceptional wedding planning services that exceed your
              expectations. We believe in crafting personalized weddings that
              reflect your personality, style, and love. Our goal is to ensure
              that every detail is flawlessly executed, allowing you to enjoy a
              stress-free and joyous celebration.From concept to execution.
            </li>
            <li>
              At Happilife Wedding Planner, our mission is to provide
              exceptional wedding planning services that exceed your
              expectations. We believe in crafting personalized weddings that
              reflect your personality, style, and love. Our goal is to ensure
              that every detail is flawlessly executed, allowing you to enjoy a
              stress-free and joyous celebration.From concept to execution.
            </li>
            <li>
              we handle every aspect of your wedding, ensuring a seamless and
              spectacular event.Whether it's a beachside ceremony or a mountain
              retreat, we specialize in planning weddings in stunning locations
              around the world.
            </li>
          </ol>
          <Box className="image-container">
            <img src={whyChooseImage} alt="why-choose" />
          </Box>
          <ol start={4}>
            <li>
              At Happilife Wedding Planner, our mission is to provide
              exceptional wedding planning services that exceed your
              expectations. We believe in crafting personalized weddings that
              reflect your personality, style, and love. Our goal is to ensure
              that every detail is flawlessly executed, allowing you to enjoy a
              stress-free and joyous celebration.From concept to execution.
            </li>
            <li>
              At Happilife Wedding Planner, our mission is to provide
              exceptional wedding planning services that exceed your
              expectations. We believe in crafting personalized weddings that
              reflect your personality, style, and love. Our goal is to ensure
              that every detail is flawlessly executed, allowing you to enjoy a
              stress-free and joyous celebration.From concept to execution.
            </li>
          </ol>
        </Box>
      </WhyChooseUsContainer>
    );
  };

  howItWorkSection = () => {
    return (
      <HowItWorkContainer component="section" id="how-it-work">
        <MainTitle>How it works</MainTitle>
        <Box className="how-it-work-items-container">
          <Box className="how-it-work-item-container">
            <Box className="how-it-work-circle-border">
              <Box className="how-it-work-circle">
                <img
                  src={howItWorkImage}
                  alt="how-it-work"
                  className="how-it-work"
                />
              </Box>
            </Box>
            <Typography className="how-it-work-title">
              Share you requirements
            </Typography>
            <Typography className="how-it-work-description">
              Tell us your event date, budget, location , type of venue, guests
              count etc.
            </Typography>
          </Box>
          <Box className="how-it-work-item-container">
            <Box className="how-it-work-circle-border">
              <Box className="how-it-work-circle">
                <img
                  src={howItWorkImage}
                  alt="how-it-work"
                  className="how-it-work"
                />
              </Box>
            </Box>
            <Typography className="how-it-work-title">
              Get a personalize proposal
            </Typography>
            <Typography className="how-it-work-description">
              Get the best deal on venue, catering, and decor as per your
              preferences.
            </Typography>
          </Box>
          <Box className="how-it-work-item-container">
            <Box className="how-it-work-circle-border">
              <Box className="how-it-work-circle">
                <img
                  src={howItWorkImage}
                  alt="how-it-work"
                  className="how-it-work"
                />
              </Box>
            </Box>
            <Typography className="how-it-work-title">
              Confirm & book
            </Typography>
            <Typography className="how-it-work-description">
              Pay a minimum amount& lock the deal within 7 days. leave the rest
              to us
            </Typography>
          </Box>
          <Box className="how-it-work-item-container">
            <Box className="how-it-work-circle-border">
              <Box className="how-it-work-circle">
                <img
                  src={howItWorkImage}
                  alt="how-it-work"
                  className="how-it-work"
                />
              </Box>
            </Box>
            <Typography className="how-it-work-title">
              Get a personalize proposal
            </Typography>
            <Typography className="how-it-work-description">
              Get the best deal on venue, catering, and decor as per your
              preferences.
            </Typography>
          </Box>
        </Box>
      </HowItWorkContainer>
    );
  };

  vendorSection = () => {
    return (
      <VendorTeamContainer component="section" id="vender-team">
        <MainTitle>Build a vendor team</MainTitle>
        <Typography className="vendor-description">
          Planning your dream wedding starts here. We’re excited to help you
          every step of the way! Whether you have questions, need inspiration,
          or are ready to start planning, we’re here for you.
        </Typography>
        <Box className="vendor-items-first-row">
          <VendorCard
            isType1
            title="Wedding venue"
            description="Step into a world of elegance and romance at our breathtaking
                wedding venues."
            buttonText="Explore venues"
            vendorImage={vendorImage}
          />
          <VendorCard
            isType1
            title="Vendors"
            description="Step into a world of elegance and romance at our breathtaking
            wedding venues."
            buttonText="Start your research"
            vendorImage={vendorImage}
          />
        </Box>
        <Box className="vendor-items-second-row">
          <VendorCard
            isType1={false}
            title="Planning tools"
            description="Custom planning tools to manage your checklist , budget and
            guests."
            buttonText="Discover"
            vendorImage={vendorImage2}
          />
          <VendorCard
            isType1={false}
            title="Inspirations"
            description="Custom planning tools to manage your checklist , budget and
            guests."
            buttonText="Discover"
            vendorImage={vendorImage3}
          />
          <VendorCard
            isType1={false}
            title="Products"
            description="Custom planning tools to manage your checklist , budget and
            guests."
            buttonText="Discover"
            vendorImage={vendorImage4}
          />
        </Box>
      </VendorTeamContainer>
    );
  };

  shopSection = () => {
    return (
      <ShopContainer component="section" id="shop">
        <MainTitle>Shop</MainTitle>
        <Typography className="shop-description">
          Stay ahead of the curve with the latest trends and expert tips for
          planning your perfect Indian wedding. From traditional elements to
          modern twists, we've got you covered with the best advice to make your
          special day truly unforgettable.
        </Typography>
        <Box className="shop-container">
          <ShoppingCard
            image_url={shopImag1}
            title="Bangles"
            description="Step into a world of elegance and romance at our breathtaking wedding venues."
          />
          <ShoppingCard
            image_url={shopImag2}
            title="Varmala"
            description="Step into a world of elegance and romance at our breathtaking wedding venues."
          />
          <ShoppingCard
            image_url={shopImag3}
            title="Lehenga"
            description="Your wedding day is one of the most significant and memorable days of your life"
          />
          <ShoppingCard
            image_url={shopImag4}
            title="Jewelry"
            description="A destination wedding offers a unique and magical experience that combines your special day"
          />
        </Box>
        <Box className="shop-button-container">
          <MoreItemsButton>
            {configJSON.shopAllTrendingsText}
            <img alt="right" src={rightArrowButton} />
          </MoreItemsButton>
        </Box>
      </ShopContainer>
    );
  };

  contactUsSection = () => {
    return (
      <ContactUsContainer component="section" id="contact-us">
        <MainTitle>Get in touch with us </MainTitle>
        <Typography className="contact-us-description">
          Planning your dream wedding starts here. We’re excited to help you
          every step of the way! Whether you have questions, need inspiration,
          or are ready to start planning, we’re here for you.
        </Typography>
        <Box className="contact-us-image-container">
          <Box className="contact-us-form-container">
            <Typography className="contact-us-title">
              Get in touch with us{" "}
            </Typography>
            <Formik
              initialValues={{
                email: "",
                name: "",
                phone_number: "",
                subject_id: "",
                description: "",
              }}
              innerRef={this.formikContactRef}
              data-test-id="contact-us-formik"
              enableReinitialize
              validationSchema={validationContactUsSchema}
              onSubmit={(values) => {
                this.contactUsApiIntegration(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <TextInputFiled
                      name="name"
                      sx={{ marginBottom: "12px" }}
                      placeholder="Enter your name"
                      label="Full name"
                      data-test-id="full-name-input"
                      value={values.name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isRequired
                      type="text"
                      error={this.isError(touched.name, errors.name)}
                      helperText={this.helperText(touched.name, errors.name)}
                    />
                    <TextInputFiled
                      name="email"
                      sx={{ marginBottom: "12px" }}
                      placeholder="Enter your email"
                      label="Email"
                      data-test-id="email-input"
                      value={values.email}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isRequired
                      type="text"
                      error={this.isError(touched.email, errors.email)}
                      helperText={this.helperText(touched.email, errors.email)}
                    />
                    <TextInputFiled
                      name="phone_number"
                      sx={{ marginBottom: "12px" }}
                      placeholder="Enter your number"
                      label="Your mobile number"
                      data-test-id="mobile-number-input"
                      value={values.phone_number}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isRequired
                      type="number"
                      error={this.isError(
                        touched.phone_number,
                        errors.phone_number
                      )}
                      helperText={this.helperText(
                        touched.phone_number,
                        errors.phone_number
                      )}
                    />
                    <SelectField
                      data-test-id="subject-list-select"
                      error={this.isError(
                        touched.subject_id,
                        errors.subject_id
                      )}
                      sx={{ marginBottom: "12px" }}
                      isRequired
                      touched={touched.subject_id}
                      placeholder="Select your subject"
                      handleBlur={handleBlur("subject_id")}
                      helperText={touched.subject_id && errors.subject_id}
                      label="Subject"
                      name="subject_id"
                      options={this.state.subjectList.map((data) => ({
                        label: data.name,
                        value: data.id,
                      }))}
                      setFieldValue={setFieldValue}
                      value={values.subject_id}
                    />
                    <TextAreaFiled
                      sx={{ marginBottom: "31px" }}
                      name="description"
                      placeholder="Write your message..."
                      label="Your message"
                      data-test-id="message"
                      value={values.description}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isRequired
                      type="text"
                      error={this.isError(
                        touched.description,
                        errors.description
                      )}
                      helperText={this.helperText(
                        touched.description,
                        errors.description
                      )}
                    />
                    <Button
                      className="contact-us-button"
                      fullWidth
                      type="submit"
                    >
                      {configJSON.sendButtonText}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </ContactUsContainer>
    );
  };

  realWeddingSection = () => {
    return (
      <RealWeddingContainer component="section" id="real-wedding">
        <MainTitle>Real weddings</MainTitle>
        <Typography className="real-wedding-description">
          Experience the magic of real love stories. Our "Real Weddings"
          showcases beautiful, unforgettable weddings we've had the pleasure to
          plan. Get inspired by the unique details, stunning venues, and
          heartfelt moments that made each celebration special.
        </Typography>
        <Grid spacing={4} container className="real-wedding-container">
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                <RealWeddingCard
                  image_url={[
                    realWedding1,
                    realWedding2,
                    realWedding3,
                    realWedding4,
                  ]}
                  location="Palace Grounds, Jaipur"
                  couple_name="Priya & Raj"
                />
              </Grid>
            ))}
        </Grid>
        <Box className="real-wedding-button-container">
          <MoreItemsButton>
            Explore more real weddings
            <img alt="right" src={rightArrowButton} />
          </MoreItemsButton>
        </Box>
      </RealWeddingContainer>
    );
  };

  trendingSection = () => {
    return (
      <ShopContainer
        component="section"
        id="trending"
        sx={{ padding: "20px 0" }}
      >
        <MainTitle>Trending </MainTitle>
        <Typography className="shop-description">
          Stay ahead of the curve with the latest trends and expert tips for
          planning your perfect Indian wedding. From traditional elements to
          modern twists, we've got you covered with the best advice to make your
          special day truly unforgettable.
        </Typography>
        <Box className="shop-container">
          <ShoppingCard
            image_url={shopImag1}
            title="Vendors"
            description="Step into a world of elegance and romance at our breathtaking wedding venues."
          />
          <ShoppingCard
            image_url={shopImag2}
            title="Venues"
            description="Step into a world of elegance and romance at our breathtaking wedding venues."
          />
          <ShoppingCard
            image_url={shopImag3}
            title="Photographers"
            description="Your wedding day is one of the most significant and memorable days of your life"
          />
          <ShoppingCard
            image_url={shopImag4}
            title="Destination wedding"
            description="A destination wedding offers a unique and magical experience that combines your special day"
          />
        </Box>
        <Box className="shop-button-container">
          <MoreItemsButton>
            Browse all trendings
            <img alt="right" src={rightArrowButton} />
          </MoreItemsButton>
        </Box>
      </ShopContainer>
    );
  };

  newSletterSection = () => {
    return (
      <NewSletterContainer component="section" id="new-sletter">
        <Box className="new-sletter-white-box">
          <Typography component="h2" className="new-sletter-title">
            Newsletter
          </Typography>
          <Typography component="p" className="new-sletter-description">
            The best of inspiration and ideas delivered to you inbox{" "}
          </Typography>
          <Formik
            data-test-id="new-sletter-formik"
            innerRef={this.formikNewSletterRef}
            initialValues={{
              email: "",
              name: "",
            }}
            validationSchema={validationNewSletterSchema}
            onSubmit={(values) => {
              this.newSletterApiIntegration(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form onSubmit={handleSubmit} noValidate>
                  <TextInputFiled
                    name="email"
                    placeholder="Enter your email"
                    label="Email"
                    data-test-id="email-input"
                    value={values.email}
                    handleChange={(event) => {
                      handleChange(event);
                      this.handleChangeIsEmailAlreadyUsed();
                    }}
                    handleBlur={handleBlur}
                    isRequired
                    type="text"
                    error={this.isError(
                      touched.email,
                      errors.email,
                      this.state.isEmailAlreadyUsed
                    )}
                    helperText={this.helperText(
                      touched.email,
                      errors.email,
                      this.state.isEmailAlreadyUsed,
                      "This email is already used for newsletter"
                    )}
                  />
                  <TextInputFiled
                    name="name"
                    sx={{ marginBottom: "40px" }}
                    placeholder="Enter your name"
                    label="Name"
                    data-test-id="name-input"
                    value={values.name}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isRequired
                    type="text"
                    error={this.isError(touched.name, errors.name)}
                    helperText={this.helperText(touched.name, errors.name)}
                  />
                  <Box className="button-container">
                    <SubscribeButton
                      type="submit"
                      disabled={this.state.isEmailAlreadyUsed}
                    >
                      {configJSON.subscribeButtonText}
                    </SubscribeButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </NewSletterContainer>
    );
  };

  doNotSection = () => {
    return (
      <DoNotContainer component="section" id="do-not-take-word">
        <Typography component="h2" className="do-not-take-word-title">
          Don’t take our words for it -{" "}
        </Typography>
        <Typography className="do-not-take-word-description">
          customers say it best
        </Typography>
        <Box className="do-not-image-container">
          <img src={doNotWorkImage} className="do-not-image" alt="do-not" />
        </Box>
        <Box className="swipe-container">
          <Swiper
            data-test-id="swiper"
            className="carousel-container"
            modules={[Navigation]}
            spaceBetween={32}
            breakpoints={breakpoints}
            navigation={{
              prevEl: ".custom-prev",
              nextEl: ".custom-next",
            }}
          >
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <SwiperSlide key={index}>
                  <Box className="do-not-card">
                    <Box className="rating-star-container">
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                    </Box>
                    <Typography className="rating-description">
                      <q>
                        Happilife Wedding Planner made our dream wedding a
                        reality! From the initial consultation to the big day,
                        their team was incredibly attentive and professional.
                      </q>
                    </Typography>
                    <Box className="image-text-container">
                      <img src={userImage} alt="user" className="user-image" />
                      <Box className="text-container">
                        <Typography className="user-name">
                          Sanjana Mohan
                        </Typography>
                        <Typography className="location">Bangalore</Typography>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
          </Swiper>

          <Button className="custom-prev" data-test-id="custom-prev">
            <img src={leftArrow} alt="left" />
          </Button>

          <Button className="custom-next" data-test-id="custom-next">
            <img src={rightArrow} alt="right" />
          </Button>
        </Box>
      </DoNotContainer>
    );
  };

  faqSection = () => {
    return (
      <FAQContainer component="section" id="faqs">
        <MainTitle>You may still be wondering...</MainTitle>
        <Typography className="faq-description">
          Planning an Indian wedding involves many details and decisions. We
          know you might have lots of questions, and we’re here to help! Below
          are some of the most frequently asked questions we receive from
          couples and their families. If you don’t find the answer you’re
          looking for, please feel free to contact us directly. We’re always
          here to assist you in making your special day as perfect as possible.
        </Typography>
        <Box className="faq-color-container">
          {this.state.faqQuestionAnswer.map((data) => (
            <Accordion className="faq-accordion" key={data.id}>
              <AccordionSummary
                expandIcon={
                  <>
                    <img src={plusImage} className="plus-image" alt="plus" />
                    <img src={minusImage} className="cross-image" alt="cross" />
                  </>
                }
              >
                <Typography variant="h4" className="accordion-heading">
                  {data.attributes.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion-details">
                  {data.attributes.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </FAQContainer>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box
        style={{
          height: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Loader isLoading={this.state.isLoading} />
        <Header navigation={this.props.navigation} />
        {this.carouselSection()}
        <Box
          style={{
            backgroundImage: `url(${backgroundDesign})`,
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
          }}
        >
          <CustomContainer maxWidth="lg">
            {this.statSection()}
            {this.whoAreSection()}
            {this.passionateSection()}
            {this.whyChooseSection()}
            {this.howItWorkSection()}
            {this.vendorSection()}
            {this.shopSection()}
            {this.contactUsSection()}
            {this.realWeddingSection()}
            {this.trendingSection()}
            {this.newSletterSection()}
            {this.doNotSection()}
            {this.faqSection()}
          </CustomContainer>
          <Footer />
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const validationContactUsSchema = Yup.object({
  name: Yup.string()
    .required("Full name is required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Full name can only contain letters")
    .test(
      "not-only-digits",
      "Full name cannot contain only digits",
      (value) => {
        return !/^\d+$/.test(value);
      }
    )
    .max(50, "Maximum 50 characters are allowed"),
  email: Yup.string()
    .email("Email should be in a valid email format (user@example.com)")
    .required("Email is required")
    .trim(),
  phone_number: Yup.string()
    .required("Mobile number is required")
    .trim(),
  subject_id: Yup.string().required("Subject is required"),
  description: Yup.string()
    .required("Message is required")
    .max(500, "Maximum 500 characters are allowed")
    .trim(),
});

const validationNewSletterSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters")
    .test("not-only-digits", "Name cannot contain only digits", (value) => {
      return !/^\d+$/.test(value);
    })
    .max(50, "Maximum 50 characters are allowed"),
  email: Yup.string()
    .email("Email should be in a valid email format (user@example.com)")
    .required("Email is required")
    .trim(),
});
const CarouselStyle = styled(Box)(({ theme }) => ({
  height: "855px",
  width: "100%",
  position: "relative",
  flex: "0 0 auto",
  "& .carousel-container": {
    height: "100%",
    "& ul,& li": { height: "100%" },
    "& .slider-image": {
      height: "100%",
      width: "100%",
      "& img, & video": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        objectPosition: "center",
      },
      "& video": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        objectPosition: "center",
      },
    },
    "& .carousel-button-group": {
      position: "absolute",
      inset: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      "& .carousel-button-group-container": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
      "& .previous-button,& .next-button": {
        height: "44px",
        width: "44px",
        minWidth: "unset",
        padding: "0",
        borderRadius: "50%",
        backgroundColor: "#ffffff",
        zIndex: 1,
      },
    },
  },
  "& .carousel-text-container": {
    position: "absolute",
    inset: "0",
    backgroundColor: "#00000099",
  },
  "& .vendor-button-container": {
    position: "absolute",
    top: "31px",
    right: "0",
    left: "0",
  },
  "& .vendor-button": {
    zIndex: "1",
    border: "1px solid #ffffff",
    borderRadius: "30px",
    padding: "11px",
    gap: "8px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#ffffff",
    textTransform: "none",
    float: "right",
  },
  "& .filter-item-text-main-container": {
    inset: "0",
    position: "absolute",
    display: "flex",
    alignItems: "center",
  },
  "& .filter-item-text-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  "& .main-heading": {
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "52px",
    textAlign: "center",
    color: "#ffffff",
    maxWidth: "545px",
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 30px",
      maxWidth: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "36px",
      padding: "0 10px",
      lineHeight: "48px",
    },
  },
  "& .second-heading": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "34px",
    textAlign: "center",
    color: "#ffffff",
    maxWidth: "679px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
      maxWidth: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      padding: "0 14px",
      lineHeight: "32px",
    },
  },
  "& .search-container": {
    backgroundColor: "#ffffff",
    padding: "9.5px 8px",
    borderRadius: "30px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      borderRadius: "10px",
      flexDirection: "column",
      width: "75%",
    },
  },
  "& .select-dropdown-container": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .search-button": {
    gap: "8px",
    backgroundColor: "#7f1187 !important",
    borderRadius: "30px",
    fontFamily: "Poppins",
    fontSizeVendorTeamContainer: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "10px 33.21px 10px 8px",
    color: "#FFF",
    textTransform: "none",
  },
  "& .third-heading": {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#ffffff",
    marginTop: "8px",
    marginBottom: "10pxs",
  },
  "& .check-box-controller": {
    display: "flex",
    flexDirection: "column",
    width: "300.55px",
    "& label": {
      margin: "0",
    },
    "& .MuiTypography-root": {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      color: "#ffffff",
    },
    "& .Mui-checked + .MuiTypography-root": {
      fontWeight: "700",
    },
    "& .checked-unchecked-image": {
      height: "20px",
      width: "20px",
    },
  },
}));

const SelectDropdown = styled(Select)(({ theme }) => ({
  width: "100%",
  "& .select-dropdown__control": {
    width: "198px",
    padding: "4px 8px",
    border: "none !important",
    boxShadow: "none",
    "@media(max-width:630px)": {
      width: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .location-image": {
    height: "16px",
    width: "16px",
  },
  "& .dropdown": {
    height: "20px",
    width: "20px",
  },
  "*": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#292524 !important",
  },
  "& .select-dropdown__indicator-separator": {
    display: "none",
  },
  "& .select-dropdown__option--is-selected": {
    backgroundColor: "#7f1187",
    color: "#ffffff !important",
  },
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  zIndex: "2",
  position: "relative",
  marginTop: "-113px",
  marginBottom: "40px",
  "& .starts-main-container": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .stats-container": {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "#ffffff",
    padding: "58px 55px",
    boxShadow: "0px 4px 4px 0px #00000040",
    maxWidth: "1150px",
    boxSizing: "border-box",
    rowGap: "10px",
    [theme.breakpoints.down("md")]: {
      padding: "55px 30px !important",
    },
    "@media(max-width: 700px)": {
      padding: "40px 15px !important",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  "& .stat-item-fix-length": {
    flex: "0 0 176px !important",
    [theme.breakpoints.down("md")]: {
      flex: "1 !important",
    },
    "@media(max-width: 700px)": {
      padding: "21px 5px !important",
      flex: "0 0 33.33% !important",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 49.8% !important",
    },
  },
  "& .divider-3": {
    "@media(max-width: 700px)": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  "& .divider-4": {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "& .divider-2": {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "& .stat-item": {
    textAlign: "center",
    boxSizing: "border-box",
    flex: "1",
    padding: "31px 15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: "21px 10px !important",
    },
    "@media(max-width: 700px)": {
      padding: "21px 5px !important",
      flex: "0 0 33%",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 49.8% !important",
    },
    "& h2": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#44403C",
    },
    "& p": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#44403C",
      marginTop: "12px",
    },
  },
  "& .divider": {
    height: "100%",
    width: "1px",
    backgroundColor: "#44403C",
    alignSelf: "center",
  },
}));
const MainTitle = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "40px",
  fontWeight: "500",
  lineHeight: "50px",
  color: "#1C1917",
  textAlign: "center",
});

const WhoAreSection = styled(Box)({
  padding: "30px 0",
  "& .image-with-overlay": {
    marginTop: "32px",
    height: "490px",
    borderRadius: "8px",
    width: "100%",
    background: `linear-gradient(#00000073,#00000073), url(${whoAreImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
});

const PassionateContainer = styled(Box)({
  padding: "30px 0",
  textAlign: "center",
  "& .passionate-main-text": {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "37px",
    textAlign: "center",
    color: "#1C1917",
    margin: "auto",
    marginBottom: "16px",
    maxWidth: "664px",
  },
  "& .passionate-second-main-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#57534E",
  },
});

const WhyChooseUsContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  "& .text-image-container": {
    display: "flex",
    gap: "30px",
    marginTop: "60px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    "& ol": {
      flex: "1 1 auto",
      margin: "0",
      paddingLeft: "20px",
      "*": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26px",
        color: "#57534E",
        [theme.breakpoints.down("sm")]: {
          textAlign: "justify",
        },
      },
      "& li": {
        marginBottom: "30px",
        "&:last-child": {
          marginBottom: "0px",
        },
      },
    },
    "& .image-container": {
      flex: "0 0 339px",
    },
    "& img": {
      width: "100%",
      height: "100%",
      minHeight: "549px",
      overflow: "hidden",
      borderRadius: "8px",
      objectFit: "cover",
      backgroundPosition: "center center",
    },
  },
}));

const HowItWorkContainer = styled(Box)({
  padding: "30px 0",
  "& .how-it-work": {
    height: "61px",
    width: "61px",
  },
  "& .how-it-work-circle": {
    width: "184.01px",
    height: "184.01px",
    backgroundColor: "#80118726",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .how-it-work-circle-border": {
    border: "1px solid #8011878A",
    padding: "8.49px",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "50%",
  },
  "& .how-it-work-title": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
    color: "#801187",
  },
  "& .how-it-work-description": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "18px",
    textAlign: "center",
    color: "#57534E",
    marginTop: "9px",
    maxWidth: "294px",
  },
  "& .how-it-work-item-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "fit-content",
  },
  "& .how-it-work-items-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "39px",
    marginTop: "40px",
  },
});

const VendorTeamContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  "& .vendor-description": {
    margin: "18px 0 60px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
  },
  "& .vendor-items-first-row": {
    display: "flex",
    gap: "32px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  "& .vendor-items-second-row": {
    display: "flex",
    gap: "32px",
    padding: "32px 29px 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px 0",
    },
  },
}));

const ShopContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  "& .shop-description": {
    margin: "18px 0 60px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
  },
  "& .shop-container": {
    display: "flex",
    justifyContent: "center",
    padding: "0 0px 28px",
    flexWrap: "wrap",
    gap: "43px",
  },
  "& .shop-button-container": {
    textAlign: "center",
  },
}));

const MoreItemsButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
  textAlign: "left",
  color: "#7F1287",
  textTransform: "none",
  border: "1px solid #7F1287",
  borderRadius: "30px",
  padding: "9px 11px",
  gap: "8px",
  "& img": {
    height: "24px",
    width: "24px",
  },
});

const ContactUsContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  "& .contact-us-description": {
    margin: "18px 0 60px",
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    color: "#57534E",
  },
  "& .contact-us-form-container": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "39px 78px 108px",
    borderRadius: "8px",
    maxWidth: "667px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      padding: "39px 50px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  "& .contact-us-image-container": {
    backgroundImage: `url(${contactUsBackground})`,
    padding: "52px",
    borderRadius: "8px",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "52px 30px",
    },
  },
  "& .contact-us-title": {
    fontFamily: "Poppins",
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "50px",
    color: "#1C1917",
    marginBottom: "42px",
    textAlign: "center",
  },
  "& .contact-us-button": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    color: "#FFFFFF",
    lineHeight: "24px",
    backgroundColor: "#801187 !important",
    textTransform: "none",
    borderRadius: "30px",
    padding: "15px",
  },
}));

const RealWeddingContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0 20px",
  "& .real-wedding-description": {
    textAlign: "center",
    margin: "18px 0 40px",
    lineHeight: "34px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    color: "#57534E",
  },
  "& .real-wedding-container": {
    width: "calc(100% + 32px)",
    justifyContent: "center",
    "& > .MuiGrid-item": {
      width: "100%",
    },
  },
  "& .real-wedding-button-container": {
    textAlign: "center",
    marginTop: "24px",
  },
}));

const NewSletterContainer = styled(Box)(({ theme }) => ({
  padding: "20px 20px",
  "& .new-sletter-white-box": {
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "44px 68px 85px 63px",
    maxWidth: "1130px",
    borderRadius: "8px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 35px 60px 35px",
    },
  },
  "& .new-sletter-title": {
    fontFamily: "Poppins",
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "50px",
    textAlign: "center",
    color: "#1C1917",
  },
  "& .new-sletter-description": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
    margin: "18px 0 40px",
  },
  "& .button-container": {
    textAlign: "center",
  },
}));

const DoNotContainer = styled(Box)(({ theme }) => ({
  padding: "20px 0 14px",
  "& .do-not-take-word-title": {
    fontFamily: "Poppins",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "52px",
    textAlign: "center",
    color: "#1C1917",
  },
  "& .do-not-take-word-description": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#292524",
    marginBottom: "32px",
  },
  "& .do-not-image-container": {
    height: "367px",
    width: "100%",
  },
  "& .do-not-image": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  "& .rating-star": {
    width: "20.28px",
    height: "19.64px",
  },
  "& .do-not-card": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 6px 6px 0px #00000040",
    borderRadius: "16px",
    padding: "52.68px 27px",
    height: "100%",
    boxSizing: "border-box",
  },
  "& .rating-star-container": {
    display: "flex",
    gap: "7px",
    paddingLeft: "8.34px",
  },
  "& .rating-description": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    margin: "24.68px 0 40px",
    maxWidth: "295px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
  },
  "& .image-text-container": {
    display: "flex",
    gap: "15px",
  },
  "& .user-image": {
    height: "56px",
    width: "56px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  "& .user-name": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "34px",
    letterSpacing: "-0.005em",
    color: "#292524",
    textAlign: "left",
  },
  "& .location": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "left",
    color: "#292524",
  },
  "& .swiper": {
    height: "fit-content",
    padding: "6px",
  },
  "& .swiper-slide": {
    height: "auto",
  },
  "& .custom-prev": { left: "-14px" },
  "& .custom-next": { right: "-14px" },
  "& .custom-next,& .custom-prev": {
    height: "44px",
    zIndex: 2,
    position: "absolute",
    top: "0",
    bottom: "0",
    margin: "auto",
    width: "44px",
    backgroundColor: "#FFF",
    borderRadius: "50%",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "0",
    minWidth: "unset",
    "&:disabled": {
      display: "none",
    },
  },
  "& .swipe-container": {
    position: "relative",
    marginTop: "-102px",
    "& .swiper-wrapper": {
      height: "fit-content",
    },
  },
}));

const SubscribeButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "24px",
  color: "#FFFFFF !important",
  backgroundColor: "#801187 !important",
  textTransform: "none",
  padding: "10px 71px",
  borderRadius: "30px",
});

const FAQContainer = styled(Box)({
  padding: "20px 0 0px",
  "& .faq-description": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
    margin: "18px 0 32px",
  },
  "& .faq-color-container": {
    backgroundColor: "#FBE8FC",
    padding: "59px 36px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "22px",
  },
  "& .faq-accordion": {
    border: "1px solid #E2E8F0",
    boxShadow: "none",
    borderRadius: "8px !important",
    "&::before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: "0 !important",
      padding: "0 12px",
    },
    "& .MuiAccordionSummary-root": {
      "& .MuiAccordionSummary-content": {
        margin: "23px 0",
      },
      "& .accordion-heading": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textAlign: "left",
        color: "#000000",
      },
      "& .plus-image": {
        height: "24px",
        width: "24px",
        display: "block",
      },
      "& .cross-image": {
        height: "24px",
        width: "24px",
        display: "none",
      },
    },

    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "unset",
      "& .accordion-heading": {
        fontWeight: "600",
        color: "#7F1187",
      },
      "& .MuiAccordionSummary-content": {
        margin: "10px 0",
      },
      "& .plus-image": {
        height: "24px",
        width: "24px",
        display: "none",
      },
      "& .cross-image": {
        height: "24px",
        width: "24px",
        display: "block",
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: "8px 12px 16px",
    },
    "& .accordion-details": {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      textAlign: "left",
      color: "#57534E",
    },
  },
});

const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});
// Customizable Area End
