import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled,
  InputAdornment,
  IconButton,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { googlelogo, hidePassword, showPassword, WeddingLogo } from "./assets";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginSideImageWrapper from "../../../components/src/LoginSideImageWrapper.web";
import TextInputFiled from "../../../components/src/TextInputField.web";

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Email should be in a valid email format (user@example.com)")
    .required("Email is required"),
  password: Yup.string().trim().required("Password is required"),
});
// Customizable Area End

import LoginEmailController, { Props } from "./LoginEmailController";

export default class LoginEmail extends LoginEmailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginSideImageWrapper isLoading={this.state.isLoading}>
        <ScrollableContentBox data-test-id="ScrollableContentBox">
          <Box className="sign-up-form-container">
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.loginWithEmail(values);
              }}
            >
              {({
                touched,
                values,
                errors,
                setFieldValue,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <img
                      src={WeddingLogo}
                      className="wedding-logo"
                      alt="Wedding Logo"
                      onClick={this.redirectToHomePage}
                      data-test-id="wedding-logo"
                    />
                    <Typography className="sign-up-form-container-text-1">
                      Login
                    </Typography>
                    <Typography className="sign-up-form-container-text-2">
                      Get started for free
                    </Typography>
                    <SignUpGoogleButton fullWidth>
                      <img src={googlelogo} alt="Google Logo" />
                      Sign in with Google
                    </SignUpGoogleButton>
                    <Box className="or-box-center">
                      <Typography className="or-style">- or -</Typography>
                    </Box>
                    <TextInputFiled
                      name="email"
                      placeholder="Enter your email"
                      label="Email"
                      data-test-id="email-input"
                      value={values.email}
                      handleChange={(e) => {
                        handleChange(e);
                        this.changeEmailValid();
                      }}
                      handleBlur={handleBlur}
                      type="email"
                      error={
                        this.state.inValidEmail
                          ? true
                          : touched.email && !!errors.email
                      }
                      helperText={
                        this.state.inValidEmail
                          ? "Account not found or not activated"
                          : touched.email && errors.email
                      }
                    />
                    <TextInputFiled
                      label="Password"
                      name="password"
                      data-test-id="password-input"
                      placeholder="Enter your password"
                      value={values.password}
                      handleChange={(e) => {
                        handleChange(e);
                        this.changePasswordValid();
                      }}
                      handleBlur={handleBlur}
                      type={values.showPassword ? "text" : "password"}
                      error={
                        this.state.inValidPassword
                          ? true
                          : touched.password && !!errors.password
                      }
                      helperText={
                        this.state.inValidPassword
                          ? "Incorrect password"
                          : touched.password && errors.password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id="icon-toggle"
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setFieldValue(
                                  "showPassword",
                                  !values.showPassword
                                )
                              }
                              edge="end"
                            >
                              {values.showPassword ? (
                                <img
                                  className="password-images"
                                  src={showPassword}
                                  alt="show"
                                />
                              ) : (
                                <img
                                  alt="hide"
                                  src={hidePassword}
                                  className="password-images"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box className="text-align-right">
                      <Typography
                        className="forgot-password-text"
                        data-test-id="redirect-to-forgot"
                        onClick={() => this.redirectToForgotPasswordPage()}
                      >
                        Forgot Password ?
                      </Typography>
                    </Box>

                    <LoginButton
                      type="submit"
                      data-test-id="sign-up-button"
                      disabled={
                        this.state.inValidEmail || this.state.inValidPassword
                      }
                      fullWidth
                    >
                      Login
                    </LoginButton>
                    <Typography className="login-main-text">
                      Don’t have an account?{" "}
                      <Typography
                        className="login-text"
                        component="span"
                        data-test-id="sign-up"
                        onClick={() => this.goToSignUp()}
                      >
                        Sign Up
                      </Typography>
                    </Typography>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </ScrollableContentBox>
      </LoginSideImageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ScrollableContentBox = styled(Box)(({ theme }) => ({
  width: "50%",
  boxSizing: "border-box",
  height: "100%",
  padding: "30px 114.96px 30px 72.32px",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    minHeight: "fit-content",
    overflowY: "unset",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "30px 30px 68px",
  },
  "& .sign-up-form-container": {
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    display: "flex",
    "& form": {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "600px",
      },
    },
  },
  "& .login-main-text": {
    fontFamily: "Poppins",
    marginTop: "20px",
    fontSize: "14px",
    color: "#78716C",
    fontWeight: "400",
    lineHeight: "24px",
  },
  "& .login-text": {
    fontFamily: "Poppins",
    fontSize: "14px",
    cursor: "pointer",
    lineHeight: "24px",
    fontWeight: "500",
    color: "#801187",
  },
  "& .wedding-logo": {
    height: "60px",
    marginBottom: "24px",
    aspectRation: "1",
    width: "120.62px",
    cursor: "pointer",
  },
  "& .sign-up-form-container-text-1": {
    color: "#000000",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "24px",
    fontSize: "22px",
    marginBottom: "6px",
  },
  "& .sign-up-form-container-text-2": {
    fontFamily: "Poppins",
    marginBottom: "24px",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#78716C",
  },
  "& .or-box-center": {
    display: "flex",
    marginBottom: "24px",
    justifyContent: "center",
    marginTop: "24px",
    alignItems: "center",
  },
  "& .or-style": {
    fontFamily: "Inter",
    fontSize: "18px",
    color: "#A8A29E",
    fontWeight: "300",
    lineHeight: "24px",
  },
  "& .text-align-right": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  "& .forgot-password-text": {
    paddingRight: "27.19px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "right",
    color: "#801187",
    cursor: "pointer",
    lineHeight: "24px",
  },
}));

const SignUpGoogleButton = styled(Button)({
  padding: "9px 16px",
  border: "1px solid #E7E5E4",
  lineHeight: "24px",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: "500",
  textTransform: "none",
  color: "#000000",
  borderRadius: "8px",
  gap: "8px",
});

const LoginButton = styled(Button)({
  marginTop: "38.81px",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  border: "1px solid  #801187",
  color: "#FFFFFF !important",
  backgroundColor: "#801187",
  borderRadius: "8px",
  padding: "19px 20px",
  textTransform: "unset",
  "&:hover": {
    backgroundColor: "#801187",
  },
});
// Customizable Area End
