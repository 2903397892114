import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";

export const baseURL = require("../../../framework/src/config.js");

type forgotWebResponseJson = { errors: { token: string }[] } & {
  errors: { otp: string }[];
} & {
  data: { id: number };
  message: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: {
    password: string;
    confirm_password: string;
    showPassword1: boolean;
    showPassword2: boolean;
  };
  isLoading: boolean;
  inValidEmail: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  newPasswordApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      formData: {
        password: "",
        confirm_password: "",
        showPassword1: false,
        showPassword2: false,
      },
      isLoading: false,
      inValidEmail: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (webApiRequestCallId === this.newPasswordApiCallId) {
        this.handleUpdatePasswordApiResponse(webResponseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleUpdatePasswordApiResponse = (
    webResponseJson: forgotWebResponseJson
  ) => {
    if (Array.isArray(webResponseJson.errors)) {
      if (webResponseJson.errors[0].token || webResponseJson.errors[0].otp) {
        toast.error("Invalid token. please try again.");
        this.props.navigation.navigate("ForgotPassword");
        this.setState({
          isLoading: false,
        });
        return;
      }
    }
    if (webResponseJson?.message && webResponseJson?.data) {
      toast.success("Your password updated successfully");
      this.props.navigation.navigate("PasswordConfirmation");
      this.setState({
        isLoading: false,
      });
    }
  };

  componentDidMount = async () => {
    this.getAuthToken();
  };

  getAuthToken = async () => {
    const otp_token = await getStorageData("otp_token");
    if (otp_token) {
      this.setState({
        token: otp_token,
      });
    } else {
      this.props.navigation.goBack();
    }
  };

  goToLogin = async () => {
    const setVendorLogin=await getStorageData("vendorLogin")
    if(setVendorLogin){
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    else{
    this.props.navigation.navigate("LoginEmail");
    }
  };

  forgotPasswordWithEmail = async (values: {
    password: string;
    confirm_password: string;
  }) => {
    this.setState({
      isLoading: true,
    });
    const formData = new FormData();
    formData.append("data[token]", this.state.token);
    formData.append("data[new_password]", values.password);
    formData.append("data[confirm_password]", values.confirm_password);
    this.newPasswordApiCallId = await apiCall({
      endPoint: "bx_block_forgot_password/passwords",
      method: "POST",
      body: formData,
    });
  };

  // Customizable Area End
}
