import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils";

export const baseURL = require("../../../framework/src/config.js");

const timer = 5 * 60;

type verifyOtpType = { errors: { otp: string }[] } & {
  errors: { token: string }[];
} & {
  errors: { pin: string }[];
} & {
  data: { id: number; attributes: { email: string } };
  meta: {
    message: string;
    token: string[];
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  phoneNumber: string;
  timeLeft: number;
  canResend: boolean;
  otp_token: string;
  isInvalidOtp: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorOTPLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  timerId: NodeJS.Timeout | null = null;
  otpSendApiCallId: string = "";
  verifyOtpApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      phoneNumber: "",
      canResend: false,
      timeLeft: timer,
      otp_token: "",
      isInvalidOtp: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.timerId = null;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiCallId) {
        case this.otpSendApiCallId:
          this.handleResendOtpResponse(webResponseJson);
          break;
        case this.verifyOtpApiCallId:
          this.handleVerifyOtpResponse(webResponseJson);
          break;
        default:
          break;
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.startTimer();
    this.checkUserIsLoggedIn();
  };

  componentWillUnmount = async () => {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  };

  checkUserIsLoggedIn = async () => {
    const phoneNumber = await getStorageData("phoneLogin");
    if (phoneNumber) {
      this.setState({
        phoneNumber,
      });
    } else {
      this.props.navigation.goBack();
    }
  };
  formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  startTimer = () => {
    this.timerId = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timeLeft > 1) {
          return { timeLeft: prevState.timeLeft - 1, canResend: false };
        } else {
          if (this.timerId) {
            clearInterval(this.timerId);
          }
          return { timeLeft: 0, canResend: true };
        }
      });
    }, 1000);
  };

  handleResendClick = () => {
    this.resendOtpInEmail();
  };

  goToLogin = () => {
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorPhoneLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  };

  resendOtpInEmail = async () => {
    this.setState({
      isLoading: true,
    });
      this.otpSendApiCallId = await apiCall({
        endPoint: "account_block/accounts/send_otp",
        method: "POST",
        contentType: "application/json",
        token: this.state.token,
      });
  };

  handleResendOtpResponse = async (webResponseJson: {
    message: string;
    otp_token: string[];
  }) => {
    if (webResponseJson.message && webResponseJson.otp_token) {
      toast.success("OTP send sussesfully.");
      this.setState({
        otp_token: webResponseJson.otp_token[0],
        timeLeft: timer,
        canResend: false,
      });
      this.startTimer();
      await setStorageData("otp_token", webResponseJson.otp_token[0]);
    }
  };
  handleOtpSubmit = async (value: string) => {
        if(value){
          setStorageData("authToken", 'phonelogin');
            const message: Message = new Message(
                getName(MessageEnum.NavigationMessage)
              );
              message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPageWeb");
              message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
              this.send(message);  
      toast.success("User login successfully.");
        }
  };

  changeInvalidOtpValue = () => {
    this.setState({
      isInvalidOtp: false,
    });
  };

  handleVerifyOtpResponse = async (webResponseJson: verifyOtpType) => {
    if (Array.isArray(webResponseJson.errors)) {
      if (webResponseJson.errors[0].pin||webResponseJson.errors[0].otp) {
        toast.error(
          webResponseJson.errors[0].pin||webResponseJson.errors[0].otp  
        );
        this.setState({
          timeLeft: 0,
          canResend: true,
        });
      }
      if (webResponseJson.errors[0].token) {
        toast.error(webResponseJson.errors[0].token);
        this.props.navigation.navigate("EmailAccount");
      }
    }
    if (webResponseJson.data && webResponseJson.meta) {
      toast.success(webResponseJson.meta.message);
      await setStorageData("email", webResponseJson.data.attributes.email);
      await setStorageData("authToken", webResponseJson.meta.token[0]);
      this.props.navigation.navigate("Welcome");
    }
  };
  // Customizable Area End
}
