import React from "react";
import { styled, Box } from "@mui/material";
import Header from "./Header.web";
import Footer from "./Footer.web";

const backgroundImage = require("./assets/background_image.png");

interface IHeaderFooterLayout {
  navigation: any;
  children: React.ReactNode;
}

const HeaderFooterLayout: React.FC<IHeaderFooterLayout> = ({
  navigation,
  children,
}) => {
  return (
    <HeaderFooterLayoutStyle data-test-id="InspirationStyle">
      <Header className="header" navigation={navigation} isOtherPage />
      <Box className="body-data">
        {children}
        <Footer className="footer" />
      </Box>
    </HeaderFooterLayoutStyle>
  );
};

export default HeaderFooterLayout;

const HeaderFooterLayoutStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  "& .header": {
    flex: "0 0 auto",
  },
  "& .body-data": {
    backgroundImage: `url(${backgroundImage})`,
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  "& .footer": {
    flex: "0 0 auto",
  },
}));
